@import '../../styles/colors.scss';

.label {
  min-height: 21px;
  max-height: fit-content;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  display: block;
  @include color('white-shadow', $shadow: true);
  cursor: pointer;
  margin-bottom: 0.33rem;
}

.isRequired {
  color: #ff0000;
  margin-right: 0.25rem;
}

.richText {
  min-height: 21px;
  max-height: fit-content;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  display: flex;
  @include color('white-shadow', $shadow: true);
  cursor: pointer;
  margin-bottom: 0.33rem;

  .isRequired {
    margin-top: 20px;
  }
}

.input {
  display: flex;
  flex-flow: column nowrap;
  padding: 5px;

  &:focus {
    outline: none;
  }

  &:disabled {
    filter: opacity(50%);
  }

  .na {
    justify-content: flex-start;
  }

  &.inline {
    display: inline-flex;
  }
}

.twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
  .input.input,
  :global(.input.input) {
    margin-top: 0;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  .input.input,
  :global(.input.input) {
    margin-top: 0;
  }

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.space {
    justify-content: space-evenly;
  }
}

/* Is there a better way? */
.input + .row,
.input + .twoColumn,
.input + .input,
.row + .input,
.row + .twoColumn,
.row + .row,
.twoColumn + .input,
.twoColumn + .row,
.twoColumn + .twoColumn {
  margin-top: 20px;
}
