@import '../../../styles/colors.scss';

.userRow {
  display: flex;
  justify-content: space-between;

  .assignees {
    display: flex;
    flex-direction: column;
  }
}

.legend {
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  margin: auto 0;
  width: 50%;
  font-weight: bold;

  > div {
    margin: 7px;
    > span {
      @include colorStyling($text: true);
    }
    @include colorStyling($text: true);
  }

  .divider {
    border-radius: 1px;
    background-color: map-get($colors, 'grey-stroke');
    height: 2px;
  }
}

.green {
  filter: invert(42%) sepia(26%) saturate(1056%) hue-rotate(75deg)
    brightness(97%) contrast(93%);
}
