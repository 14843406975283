.sameLine {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .topMargin {
    margin-top: 30px;
  }
}

.button {
  width: 147px;
}
