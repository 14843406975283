@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';

.questions {
  height: fit-content;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  display: flex;
  align-items: center;

  .question {
    flex-grow: 1;
  }

  &.target {
    background-color: map-get($colors, 'grey-bkgd');
  }
}

.overallFooter {
  margin: auto;
}
