@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.container {
  background-color: map-get($colors, 'white');
  border-radius: 5px;
  box-shadow: 0 2px 3px hsla(0, 0%, 4%, 0.1), 0 0 0 1px hsla(0, 0%, 4%, 0.1);
  color: #4a4a4a;
  padding: 1.5rem 1.25rem 3rem;
  text-align: left;
  margin: 3rem;
  width: calc(100% - 6rem);

  @include media('<=tablet') {
    margin: 0;
  }
}

.table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: auto;
  background-color: map-get($colors, 'white');
  border: 1px solid #{map-get($colors, 'grey')};

  tr {
    border-bottom: 1px solid #{map-get($colors, 'grey')};
    padding: 0.35em;
  }

  tbody tr {
    &:nth-child(odd) {
      background-color: map-get($colors, 'grey-bkgd');
    }

    &:last-child {
      border-bottom: 0;
    }

    &.newRow td .newName {
      margin-bottom: 0;
      display: flex;
    }
  }

  .input {
    margin: 0;
    display: initial;
  }

  th, td {
    padding: 0.625em;
    text-align: left;
    word-wrap: normal;
  }

  th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}
