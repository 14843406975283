@import '../../containers/MobileHeader/mobileHeader.module.scss';

.active {
  border-bottom: 3px solid map-get($colors, 'green');
}

.folderContainer {
  padding-right: 15px;
}

.addButton {
  width: 18px;
  right: 20px;
  margin-right: 10px;
}

.allFolder {
  @include color('black', $text: true, $shadow: true);
  margin: 10px 0 0 20px;
  padding: 0;
  width: 100%;
  height: fit-content;
  text-align: left;
  font-size: 14px;
  font-style: italic;
  line-height: 16px;

  &.selected {
    @include color('white', $background: true, $text: true);
    padding: 8px 0 8px 15px;
    margin: 10px 0 0 20px;
    text-align: left;
    border-radius: 5px;
    width: 82%;
    text-decoration: underline;
  }
}

.folderList {
  list-style: none;
  margin: 0;
  padding: 8px 0 8px 15px;
  width: 100%;
  height: fit-content;
  color: map-get($colors, 'white');

  > li {
    @include color('black', $shadow: true);
    margin-top: 10px;
    margin-left: 35px;
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    height: fit-content;
    width: 75%;

    &.selected {
      @include color('white', $background: true, $text: true);
      padding: 8px 0px 8px 15px;
      margin: 10px 0px 0px 20px;
      height: fit-content;
      text-align: left;
      border-radius: 5px;
      width: 85%;
      text-decoration: underline;
    }

    &:last-child {
      text-align: center;
    }
  }
}
