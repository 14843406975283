@import '../../../styles/colors.scss';

$chartColumns: 2;
$gridPadding: 20px;
$gridGap: 20px;
$chartBorder: 1px;
$chartPadding: 20px;

/*
  Always show scrollbar to stop repaints
  Need some extra specificity.
*/
.container.container.container {
  overflow-y: scroll;
}

.datePicker {
  margin: $gridPadding;
  width: calc(100% - #{2 * $gridPadding});
}

.charts {
  display: grid;
  grid-template-columns: repeat($chartColumns, 1fr);
  padding: 0 $gridPadding $gridPadding;
  grid-gap: $gridGap;

  &:not(.readOnly) .chart {
    cursor: pointer;
    &.noData {
      cursor: not-allowed;
    }
  }

  .noData {
    /* CSS Aspect-Ratio hack to ensure cards take their full height and width. */
    height: 0;
    padding-top: 56.25%;
    position: relative;
    > div {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  > div {
    border: $chartBorder solid map-get($colors, 'blue');
    background: map-get($colors, 'white');
    border-radius: 10px;

    > div {
      height: 100%;
    }

    .chartTitle {
      padding: 5px 10px;
      font-size: 16px;
      font-weight: bold;
      height: 38px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .chart {
      padding: $chartPadding;
    }

    &.dropTarget {
      border-style: dashed;
      border-width: 2px;
    }
  }
}
