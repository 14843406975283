@import '../../../styles/colors.scss';

.text {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  width: 100%;
  height: fit-content;
  background: #fff;
  margin-bottom: 20px;
}

.grow {
  > * {
    flex-grow: 1;
  }
}
