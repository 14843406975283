@import '../../../styles/colors.scss';

.quizBox {
  border-radius: 5px;
  border: 1px solid map-get($colors, 'blue');
  margin-top: 10px;
  padding: 10px;
  width: fit-content;
  display: inline-block;
  margin-right: 10px;

  .attempt {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }

  .score {
    font-size: 16px;
    text-align: center;
  }
}
