@import '../../styles/colors.scss';

.top {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.noMargin {
  padding: 0;
  align-items: flex-end;
}

.checkbox {
  padding: 0;

  > div {
    padding: 0;
  }
}

.table {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: visible;
  margin: 10px 0;

  table {
    border: 1px solid map-get($colors, 'grey-stroke');
    font-size: 14px;
    white-space: nowrap;
    border-collapse: collapse;
    width: 100%;
    text-align: left;

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 14px 10px;
    }

    th.header {
      padding: 0;

      > div {
        padding: 14px 10px;
        display: flex;
        justify-content: space-between;

        &.asc {
          border-top: 4px solid map-get($colors, 'blue');
          padding-top: 10px;
        }
        &.desc {
          border-bottom: 4px solid map-get($colors, 'blue');
          padding-bottom: 10px;
        }

        > svg {
          fill: map-get($colors, 'blue');
          height: 18px;
        }
      }
    }

    td,
    th {
      border: 1px solid map-get($colors, 'grey-stroke');
      max-width: 500px;

      &.selector {
        padding: 5px;
        text-align: center;
        cursor: default;

        > div {
          display: inline-flex;
        }
      }
    }

    tr:nth-of-type(odd) td {
      background: map-get($colors, 'grey-bkgd');
    }
  }

  &.hoverRow table tbody {
    tr:not(.noData) {
      cursor: pointer;
      &:hover td {
        background: map-get($colors, 'white-hover');
      }
    }
    tr.noData {
      font-size: 20px;
      font-variant: small-caps;
      text-align: center;
    }
  }
}
