@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.button {
  width: fit-content;
}

.attachmentContainer {
  width: 90%;
  height: 90%;
  position: relative;

  .deleteButton,
  .testResultDeleteButton {
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    top: 5px;
    position: absolute;
  }

  .testResultDeleteButton {
    right: 5px;
  }

  .image {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: contain;
  }
}
