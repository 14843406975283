.form-fields {
  padding: 10px;
}

.form-fields--split {
  padding: 10px;
  display: flex;
  flex-flow: row;
}

.form-fields--rightSide {
  width: 47%;
  margin-left: 3%;
}

.form-fields--leftSide {
  width: 50%;
}

.form-error {
  margin-top: 20px;
  display: block;
  color: rgb(204, 51, 0);
  text-align: left;
}

.form-warning {
  display: block;
  color: rgb(255, 204, 0);
  text-align: left;
}

.formHeading {
  border-bottom: 1px solid gray;
  width: 96%;
  margin-left: 10px;
  margin-bottom: 15px;
}

.formHeading h2,
.formHeading h3 {
  margin-bottom: 0px;
}

form h4 {
  margin-bottom: 0px;
}

.required {
  color: red;
  text-align: left;
}

.form-fields--logo {
  flex-direction: column;
  text-align: center;
  width: 50%;
  margin: auto;
}

.form-fields--logo img {
  max-width: 300px;
}

.form-fields--attachmentsLeftSide {
  width: 40%;
}

.form-fields--attachmentsRightSide {
  width: 60%;
}

.form-fields--uploaded {
  position: relative;
  width: max-content;
  max-width: 100%;
}

.form-fields--hoverButton {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateY(-50%) translateX(50%);
  display: none;
}

.form-fields--attachmentsLeftSide:hover .form-fields--hoverButton {
  display: block;
}

.form-fields--attachmentsLeftSideImage {
  height: 190px;
}

.form-fields--attachmentsLeftSide img {
  max-width: 100%;
  object-fit: contain;
}

.customTheme {
  color: #fff !important;
  background-color: #595959 !important;
  opacity: 1 !important;
  border-radius: 10px;
  border: 2px solid #fff;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5));
  width: 300px;
  font-size: 16px !important;
  padding: 10px !important;
  text-align: left;
  pointer-events: auto !important;
  overflow: scroll !important;
}

.whiteTheme {
  background-color: #fff !important;
  color: #595959 !important;
  opacity: 1 !important;
  border-radius: 5px;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.5));
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 28px !important;
  padding: 10px !important;
  text-align: left;
  pointer-events: auto !important;
  overflow: scroll !important;
}

.errorTheme {
  color: #fff !important;
  background-color: #804040 !important;
  opacity: 1 !important;
  border-radius: 10px;
  border: 2px solid #fff;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5));
  width: 300px;
  font-size: 16px !important;
  padding: 10px !important;
  text-align: left;
  pointer-events: auto !important;
}

.label-info {
  margin-left: 10px;
}

[data-for='mainAddress.stateName'],
[data-for='state'] {
  margin: 0px;
  margin-top: 4px;
}

.hours-worked-table-parent {
  width: inherit;
  overflow-x: auto;
}

.hours-worked-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #949494;
}

.hours-worked-table tfoot td {
  padding: 10px;
}

.hours-worked-table td,
.hours-worked-table th {
  border: 1px solid #949494;
}

.hours-worked-table td > div {
  align-items: center;
}

.hours-worked-table .form-small-input input {
  width: 70px;
}

.form-small-dropdown {
  min-width: 75px !important;
}

.user-exists input {
  background: lightcoral;
}

.user-valid input {
  background: lightgreen;
}

.editEmployeeForm {
  width: 100%;
}

.addUserForm {
  width: 100%;
}
