.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 18px;
}

.assignAt {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: center;
}

.assignBy div:global(.name) span {
  font-size: 18px !important;
}
