.editor {
  background: #ffffff;
  border-radius: 5px;
  min-height: 100px;
  font-size: 16px;
}

.toolbar {
  text-align: left;
}
