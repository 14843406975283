@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  display: flex;
  margin: 0;
  padding: 0;
  background-color: map-get($colors, 'grey-bkgd');
  height: inherit;
  width: inherit;
  font-family: Helvetica;
}

[id='root'] {
  overflow: auto;
  width: 100%;
}
[id='app'] {
  grid-template-rows: 56px 1fr;
  height: 100vh;
  display: grid;
}

@include media('<=tablet') {
  .top-nav {
    display: none;
  }
}

@include media('>tablet') {
  .top-nav {
    flex: 1 0px;
  }
}

.alertStyle {
  @include color('blue', $background: true, $border: true);
  display: flex;
  border: {
    width: 1px;
    style: solid;
    radius: 5px;
  }
  position: relative;
  top: 60px;
  pointer-events: auto;
}

.alertStyleError {
  background-color: map-get($colors, 'red');
}

.alertStyleSuccess {
  background-color: map-get($colors, 'green');
}

.alertMessage {
  margin: 10px;
  padding-top: 2.5px;
  font-size: 22px;
  line-height: 26px;
  @include color('green', $text: true, $shadow: true);
}

.alertStyle img {
  height: 28px;
  width: 28px;
  margin: 10px;
}

.loading {
  background: rgba(0, 0, 0, 0.5);
  transition-property: background, backdrop-fiter, visibility;
  transition-duration: 500ms;
  transition-timing-function: linear;
  backdrop-filter: blur(4px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  &.hidden {
    visibility: hidden;
    backdrop-filter: none;
    background: rgba(0, 0, 0, 0);
    transition-duration: 250ms;
    div {
      width: 0;
      transition-duration: 250ms;
    }
  }
  div {
    width: 400px;
    transition-property: width;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
    max-width: 50%;
    img {
      width: 100%;
    }
  }
}
