.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-variant: small-caps;
}

.statistic {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: bold;
}
