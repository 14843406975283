@import '../../styles/colors.scss';

.container {
  display: flex;
  justify-content: center;

  > img {
    width: 48px;
    height: 48px;
  }
}

.bar {
  height: 28px;
  width: 66%;
  border: 1px solid #{map-get($colors, 'grey-stroke')};
  border-radius: 14px;
  margin-top: 8px;
  margin-right: 21px;
  padding: 2px;
  display: flex;

  .barPiece {
    height: 22px;
    background-color: #2f8738;
    border-radius: 14px;
    padding: 2px;
    @include colorStyling($background: true);

    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-of-type) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
