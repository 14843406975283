@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.home {
  display: flex;
  flex-flow: column wrap;
  background-color: map-get($colors, 'grey-bkgd');
  padding: 15px;
  overflow: auto;
  align-self: flex-start;

  @include media('<=phone') {
    flex-flow: row wrap;
  }
}

.locations {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 40px;
  width: 100%;

  h3 {
    color: map-get($colors, 'blue');
    margin: 20px;
    margin-top: 0;
    border-bottom: 2px solid map-get($colors, 'blue');
    margin-top: 20px;
  }
}

.establishments {
  display: flex;
  flex-flow: row wrap;
}

.companies {
  width: 100%;
}

.newLocation {
  @include color(
    'grey',
    $background: true,
    $text: true,
    $hover: true,
    $shadow: true
  );
  min-height: 8pc;
  width: 270px;
  margin: 15px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  line-height: normal;
  text-align: center;

  p {
    margin: 0;
  }
}

.passwordModal {
  z-index: 100;
}
