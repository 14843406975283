@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';

.buttonGroup {
  margin: 20px 0;
  padding: 0;

  .inputGroup {
    margin: 0;
    padding: 0;

    .button {
      min-width: 135px;
      max-width: 135px;
      height: 60px;
      margin: 0;
      padding: 0;
      border-radius: 0;
      border-width: 2px;
      border-color: map-get($colors, 'grey-stroke');
    }

    &:first-of-type button {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-right: 0;
    }

    &:last-of-type button {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-left: 0;
    }

    .image {
      max-width: 47px;
      max-height: 20px;
    }

    .green {
      filter: invert(42%) sepia(26%) saturate(1056%) hue-rotate(75deg)
        brightness(97%) contrast(93%);
    }

    .white {
      filter: brightness(0) invert(1);
    }

    .grey {
      filter: grayscale(1);
    }

    .greyCheck {
      filter: brightness(0.5);
      opacity: 0.5;
    }
  }
}
