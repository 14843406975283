@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.createAccountHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: inherit;
  border-bottom: 2px solid map-get($colors, 'green');
  padding-bottom: 5px;
  h2 {
    font-family: Helvetica;
    font-style: normal;
    font-size: 24px;
    line-height: 24px;
    color: map-get($colors, 'green');
    margin-top: 0px !important;
    margin-bottom: 0px;
    margin-right: 10px;
  }
  section {
    font-family: Helvetica;
    font-style: italic;
    font-size: 16px;
  }
}

.learnMore {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    color: map-get($colors, 'green');
    margin-top: 20px;
    margin-bottom: 25px;
    text-align: center;
  }
}

.loginRegistration {
  a {
    @include color('green', $hover: true, $invert: true, $text: true);
    font-weight: 700;
  }
}

.newAccountFlexbox {
  height: 100%;
  width: 100%;
  max-width: 2000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  > div {
    padding: 0 30px;
  }
}

.newAccountWholePage {
  height: 100%;
  background-color: map-get($colors, 'white');
  display: flex;
  align-items: center;
  justify-content: space-around;
}
