@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.badEntry {
  input {
    background-color: map-get($colors, 'red-light');
    border-color: map-get($colors, 'red');
  }
}

.createAccountForm {
  display: flex;
  flex-direction: column;
  label {
    font-family: Helvetica;
    font-size: 18px;
    line-height: 21px;
    @include color('white-shadow', $shadow: true);
  }
}
